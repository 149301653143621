const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.qberisktoesg.forwoodsafety.com',
    APP_CLIENT_ID: '3a8mjflel92530kmeos1e2lvoq',
    APIGATEWAY: 'https://8h34u895yd.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://xiwd4ofvi9.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'prod',
    VERSION: 'incidents-container-1.3.0',
    HOSTNAME: 'https://incidents.qberisktoesg.forwoodsafety.com',
    INCIDENTS_ACCESS_ROLE: 'IncidentsAccess',
    CREATE_INCIDENT_URL: 'https://iframe.qberisktoesg.forwoodsafety.com/node/add/isi',
    INCIDENT_LIST_URL: 'https://iframe.qberisktoesg.forwoodsafety.com/isi-list',
    IFRAME_ORIGIN_URL: 'https://iframe.qberisktoesg.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;

